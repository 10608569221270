import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import useMediaQuery from '@/hooks/useMediaQuery';
import useClickAway from '@/hooks/useClickAway';
import useWindowSize from '@/hooks/useWindowSize';
import useResizeObserver from '@/hooks/useResizeObserver';
import SearchIcon from 'images/icons/layout/Search.svg';
import CloseIcon from 'images/icons/layout/Close.svg';
import MenuIcon from 'images/icons/layout/Hamburger.svg';
import NavigationMenuItem from './NavigationMenuItem';
import LockBodyScroll from '../shared/LockBodyScroll';

const MobileMenu = ({ logoInverse, searchUrl, headerNavigationItems, utilityNavigationItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 1025px)');
  const size = useWindowSize();
  const [navigationBarRef, navigationBarSize] = useResizeObserver();
  const [navigationDropdownRef, navigationDropdownSize] = useResizeObserver();

  const maxHeight = useMemo(() => {
    if (!size.height) {
      return 0;
    }

    return size.height - (navigationBarSize?.borderBoxSize?.[0]?.blockSize ?? 0);
  }, [size.height, navigationBarSize]);

  const clickAwayRef = useClickAway(() => {
    setIsOpen(false);
  });

  const handleMenuClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  // Close menu when viewport reaches desktop breakpoint
  useEffect(() => {
    if (isDesktop) {
      setIsOpen(false);
    }
  }, [isDesktop]);

  // Close menu on escape key press
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  return (
    <FocusTrap active={isOpen}>
      <nav className="mobile-navigation">
        {isOpen && <LockBodyScroll />}
        <div ref={clickAwayRef}>
          <div className="navigation-bar" ref={navigationBarRef}>
            <a className="main-logo" href="/" aria-label="Go to home page">
              <img src={logoInverse?.directUrl} alt="" />
            </a>
            <div className="icons">
              <a className="no-style" type="button" aria-label="Go to search page" href={searchUrl}>
                <img src={SearchIcon} alt="" />
              </a>
              <button
                className="no-style"
                type="button"
                aria-label={`${isOpen ? 'Close' : 'Open'} navigation menu`}
                onClick={() => handleMenuClick()}
              >
                {isOpen ? <img src={CloseIcon} alt="" /> : <img src={MenuIcon} alt="" />}
              </button>
            </div>
          </div>
          <div
            className="navigation-dropdown"
            ref={navigationDropdownRef}
            data-is-open={isOpen}
            style={{
              '--max-height': `${maxHeight}px`,
              overflowY:
                (navigationDropdownSize?.borderBoxSize?.[0]?.blockSize ?? 0) === maxHeight
                  ? 'scroll'
                  : 'visible'
            }}
            aria-hidden={!isOpen}
          >
            <ul className="navigation-list main-navigation">
              {headerNavigationItems.map((item) => (
                <NavigationMenuItem item={item} key={item.nodeId} isParentOpen={isOpen} />
              ))}
            </ul>
            <ul className="navigation-list utility-navigation">
              {utilityNavigationItems.map((item) => (
                <NavigationMenuItem item={item} key={item.nodeId} isParentOpen={isOpen} />
              ))}
            </ul>
          </div>
        </div>
        <div className="navigation-dropdown-overlay" data-is-open={isOpen} />
      </nav>
    </FocusTrap>
  );
};

export default MobileMenu;
