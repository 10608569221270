import { Fragment } from 'react';
import useLockBodyScroll from '@/hooks/useLockBodyScroll';

const LockBodyScroll = () => {
  useLockBodyScroll();

  return Fragment;
};

export default LockBodyScroll;
