import React from 'react';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const NavigationMenu = ({ isWhiteLabel, ...props }) => (
  <header className="header-navigation">
    {!isWhiteLabel && (
      <>
        <DesktopMenu {...props} />
        <MobileMenu {...props} />
      </>
    )}
  </header>
);

export default NavigationMenu;
