import React, { useRef } from 'react';
import classNames from 'classnames';
import { useCssVariables } from '@/utilities';
import PlayIcon from './PlayIcon';

const VideoItemComponent = ({ videoItem, gridClass, dynamicImage, selectContent }) => {
  const dynamicImageContainerRef = useRef(null);

  useCssVariables(dynamicImageContainerRef, {
    '--background-image': `url("${dynamicImage}")`
  });

  return (
    <div ref={dynamicImageContainerRef} className={classNames('item', gridClass)}>
      <button
        className="btn-no-style overlay"
        type="button"
        onClick={() => selectContent(videoItem.guid)}
      >
        {videoItem.videoUrl && <PlayIcon iconColor={videoItem.playIconColor} />}
        <div className="video-content">
          <div className="video-title" dangerouslySetInnerHTML={{ __html: videoItem.title }} />
          <div
            className="video-sub-title"
            dangerouslySetInnerHTML={{ __html: videoItem.subTitle }}
          />
        </div>
      </button>
    </div>
  );
};

export default VideoItemComponent;
