import React from 'react';
import { Element } from 'react-scroll';

import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import ContentDivider from '@/components/widgets/shared/ContentDivider';
import AnchorWrapper from '@/components/layout/shared/AnchorWrapper';
import { getSectionClassNames } from '@/utilities/component-color';

const ProductsByBUComponent = ({
  hasContentDivider,
  prettyUrlTitle,
  products,
  title,
  componentBackgroundColor
}) => (
  <section className={getSectionClassNames(componentBackgroundColor)}>
    <div className="container-fluid">
      <div className="section-gutter">
        <ConditionalWrapper
          condition={prettyUrlTitle}
          wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
        >
          <div className="section-products-list">
            <h2>{title}</h2>
            <ul className="list">
              {products &&
                products.map((item) => (
                  <li className="item" key={item.name}>
                    <AnchorWrapper url={item.url} target="_self" name={item.name}>
                      <span>{item.name}</span>
                    </AnchorWrapper>
                  </li>
                ))}
            </ul>
          </div>
        </ConditionalWrapper>
      </div>
      {hasContentDivider && <ContentDivider />}
    </div>
  </section>
);

export default ProductsByBUComponent;
