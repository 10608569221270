import React from 'react';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';

const Highlight = ({ description, icon, link, target, title }) => (
  <div className="highlight">
    <LazyLoadImg src={icon} alt="highlight icon" />
    <h3>
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </h3>
    {description && <h4 dangerouslySetInnerHTML={{ __html: description }} />}
    {link && (
      <a href={link} {...(target && { target })}>
        Read More
      </a>
    )}
  </div>
);

export default Highlight;
