import React from 'react';

const GlobalComponentContainer = ({ parentPageTitle, parentPageUrl, shortDescription, title }) => (
  <div className="global-props">
    {parentPageUrl && (
      <div className="parent-page-link">
        <a href={parentPageUrl} title="Parent Page">
          {parentPageTitle}
        </a>
      </div>
    )}
    {title && <h2>{title}</h2>}
    {shortDescription && <div dangerouslySetInnerHTML={{ __html: shortDescription }} />}
  </div>
);

export default GlobalComponentContainer;
