import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import DesktopContainer from '@/components/shared/DesktopContainer';
import TabletAndMobileContainer from '@/components/shared/TabletAndMobileContainer';
import { getSectionClassNames } from '@/utilities/component-color';

const AnimationComponent = ({ animation, componentBackgroundColor }) => (
  <section className={getSectionClassNames(componentBackgroundColor)}>
    <div className="container-fluid">
      <div className="section-gutter">
        <div className="section-animation">
          <DesktopContainer>
            <Player
              src={animation.animationUrl}
              background={animation.backgroundColor}
              speed={animation.animationSpeed}
              style={{
                width: animation.width,
                height: animation.height
              }}
              loop={animation.loop}
              autoplay={animation.autoplay}
              hover={animation.hover}
              mode={animation.playMode}
            />
          </DesktopContainer>
          <TabletAndMobileContainer>
            <Player
              src={animation.animationUrl}
              background={animation.backgroundColor}
              speed={animation.animationSpeed}
              style={{
                width: animation.deviceWidth,
                height: animation.deviceHeight
              }}
              loop={animation.loop}
              autoplay={animation.autoplay}
              hover={animation.hover}
              mode={animation.playMode}
            />
          </TabletAndMobileContainer>
        </div>
      </div>
    </div>
  </section>
);

export default AnimationComponent;
