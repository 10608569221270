import React from 'react';

const Checkbox = ({
  getNewsroomData,
  isSelected,
  label,
  modifyFilters,
  setFilters,
  type,
  value
}) => (
  <div key={value} className="form-group">
    <input
      id={value}
      type="checkbox"
      checked={isSelected}
      onChange={() => {
        const filters = modifyFilters(type, value, false);
        setFilters(filters);
        getNewsroomData(1, filters, false);
      }}
    />
    <label htmlFor={value} name={value}>
      {label}
    </label>
  </div>
);

export default Checkbox;
