import React from 'react';

const AnchorWrapper = ({ children, name, target, url }) =>
  url ? (
    <a href={url} {...(target && { target })} title={`Go to ${name}`}>
      {children}
    </a>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );

export default AnchorWrapper;
