import React, { useState } from 'react';
import ReactModal from 'react-modal';
import DesktopAndTabletContainerFull from '@/components/shared/DesktopAndTabletContainerFullWidth';
import MobileContainerFull from '@/components/shared/MobileContainerFullWidth';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import VideoModal from '@/components/widgets/shared/VideoModal';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';
import { getSectionClassNames } from '@/utilities/component-color';

const IconText30By70ContentComponent = ({ iconTextItems, componentBackgroundColor }) => {
  const [selectedContentGuid, selectContent] = useState(null);

  const setSelectedContent = (guid) => {
    if (guid === selectedContentGuid) {
      selectContent(null);
    } else {
      selectContent(guid);
    }
  };

  const selectedContent =
    iconTextItems && iconTextItems.find((item) => item.guid === selectedContentGuid);

  ReactModal.setAppElement('#wrapper');

  return (
    <section className={getSectionClassNames(componentBackgroundColor)}>
      <div className="container-fluid">
        {iconTextItems.map((item) => (
          <div className="section-gutter-half" key={item.guid}>
            <ConditionalWrapper>
              <div className={`section-icon-text ${item.textPosition}`} key={item.guid}>
                <div className="content">
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>

                <div className="media">
                  <ConditionalWrapper>
                    {item.image && item.image.url && (
                      <ConditionalWrapper
                        condition={item.imageUrl}
                        wrapper={(children) => (
                          <a
                            href={item.imageUrl}
                            target={item.imageUrlTarget}
                            title={`Link for ${item.altText || item.title}`}
                          >
                            {children}
                          </a>
                        )}
                      >
                        {item.imageMobile && item.imageMobile.url ? (
                          <>
                            <MobileContainerFull>
                              <LazyLoadImg src={item.imageMobile} alt={item.altText} />
                            </MobileContainerFull>
                            <DesktopAndTabletContainerFull>
                              <LazyLoadImg src={item.image} alt={item.altText} />
                            </DesktopAndTabletContainerFull>
                          </>
                        ) : (
                          <LazyLoadImg src={item.image} alt={item.altText} />
                        )}
                      </ConditionalWrapper>
                    )}
                  </ConditionalWrapper>
                </div>
              </div>
            </ConditionalWrapper>
          </div>
        ))}
        <VideoModal
          {...selectedContent}
          selectFunction={setSelectedContent}
          isActive={!!selectedContentGuid}
        />
      </div>
    </section>
  );
};

export default IconText30By70ContentComponent;
