import React from 'react';
import ReactDOMServer from 'react-dom/server';

import arrowLeft from 'images/icons/layout/Arrow-Left-Blue.svg';

const BreadCrumb = ({ breadCrumbs }) => {
  const desktopBreadCrumb = breadCrumbs
    .map((breadCrumb) => {
      if (breadCrumb.url) {
        return ReactDOMServer.renderToStaticMarkup(
          <a href={breadCrumb.url} title={`Back to ${breadCrumb.name}`}>{`${breadCrumb.name}`}</a>
        );
      }

      return `${breadCrumb.name}`;
    })
    .join(' / ');

  const mobileBreadCrumb = breadCrumbs.reverse().find((breadCrumb) => breadCrumb.url);

  return (
    <div className="section-white">
      <div className="container-fluid">
        <div className="br-md breadcrumb" dangerouslySetInnerHTML={{ __html: desktopBreadCrumb }} />
        <div className="br-xs breadcrumb">
          <img src={arrowLeft} alt="Back Arrow" />
          <a
            className="mobile-breadcrumb"
            href={mobileBreadCrumb.url}
            title={`Back to ${mobileBreadCrumb.name}`}
          >
            {`${mobileBreadCrumb.name}`}
          </a>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
