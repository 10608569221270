import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

export const FadeInContainer = ({ direction = 'up', children }) => {
  const container = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const { current } = container;

    if (!current) return undefined;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setIsVisible((prev) => prev || entry.isIntersecting));
    });
    observer.observe(current);

    return () => observer.unobserve(current);
  }, []);

  return (
    <div
      className={classNames(
        'fade-in-container',
        isVisible ? 'visible' : 'invisible',
        !isVisible && direction === 'up' && 'translate-up',
        !isVisible && direction === 'down' && 'translate-down',
        !isVisible && direction === 'left' && 'translate-left',
        !isVisible && direction === 'right' && 'translate-right'
      )}
      ref={container}
    >
      {children}
    </div>
  );
};

export default FadeInContainer;
