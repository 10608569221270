import React, { useEffect, useState } from 'react';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import ScrollContainer from '@/components/shared/ScrollContainer';
import HorizontalTile from './HorizontalTile';

const HorizontalTilesGroupComponent = ({ horizontalTilesGroup }) => {
  const { header, description, tiles } = horizontalTilesGroup;
  const [screenWidth, setScreenWidth] = useState(null);

  useEffect(() => {
    if (!window) return undefined;

    setScreenWidth(window.innerWidth);

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="horizontal-tile-group">
      <div className="container-fluid">
        <ConditionalWrapper
          condition={screenWidth > 1024}
          wrapper={(children) => <ScrollContainer blockHeight={600}>{children}</ScrollContainer>}
        >
          <div className="horizontal-tile-group-info">
            <h2>{header}</h2>
            <p>{description}</p>
          </div>
          {tiles?.map((tile) => (
            <HorizontalTile key={tile.header} tile={tile} />
          ))}
        </ConditionalWrapper>
      </div>
    </section>
  );
};

export default HorizontalTilesGroupComponent;
