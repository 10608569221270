import React from 'react';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';

const Card = ({
  altText,
  contentType,
  link,
  rank,
  searchItemId,
  submit,
  title,
  thumbnailImage
}) => {
  const hasImage = thumbnailImage && thumbnailImage.url;

  return (
    <div className="card">
      <div className="content">
        <button
          type="button"
          className="btn-no-style"
          onClick={() =>
            submit({
              link,
              rank,
              searchItemId
            })
          }
          title={`Link to ${title}`}
        >
          <div className={`inner-image${thumbnailImage ? '' : ' no-thumbnail'}`}>
            {hasImage && (
              <LazyLoadImg src={thumbnailImage} alt={altText || `${title} Thumbnail Image`} />
            )}
          </div>
          <div className="inner-content">
            <span className="section-header">{contentType}</span>
            <p className="title">{title}</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Card;
