import React, { useRef, useEffect, useCallback } from 'react';
import { Element } from 'react-scroll';
import classNames from 'classnames';
import { getSectionClassNames } from '@/utilities/component-color';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';

const FeaturedCardComponent = ({
  prettyUrlTitle,
  title,
  image,
  altText,
  text,
  componentBackgroundColor,
  isAnimated,
  hasCtaButton,
  ctaButtonText,
  ctaButtonUrl,
  ctaButtonTarget,
  ctaButtonColor
}) => {
  const cardRef = useRef(null);
  const imageRef = useRef(null);
  const textRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (!isAnimated || !cardRef.current || !imageRef.current || !textRef.current) return;

    let heightAnchor;
    if (window.innerWidth > 1024) {
      heightAnchor = 650;
    } else {
      heightAnchor = 500;
    }

    const offsetTop = cardRef.current.offsetTop ?? 0;
    let percentage = (window.scrollY + 200 - offsetTop) / heightAnchor;

    percentage = Math.min(percentage, 0);

    const windowNormalization = heightAnchor / window.innerHeight;
    const offset = Math.min(percentage * (heightAnchor / (10 / windowNormalization)), heightAnchor);

    imageRef.current.style.transform = `translate3d(0, ${(offset * 3) / 2}px, 0)`;
    textRef.current.style.transform = `translate3d(0, ${(offset * 5) / 4}px, 0)`;
  }, [isAnimated, cardRef, imageRef, textRef]);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <ConditionalWrapper
      condition={prettyUrlTitle}
      wrapper={(children) => (
        <Element name={prettyUrlTitle} id={prettyUrlTitle} className="anchor-wrapper">
          {children}
        </Element>
      )}
    >
      <section
        className={classNames('featured-card', getSectionClassNames(componentBackgroundColor))}
        ref={cardRef}
      >
        <div className="container-fluid">
          <div className="inner">
            <img src={image} alt={altText} className="featured-image" ref={imageRef} />
            <div className="content" ref={textRef}>
              <h2>{title}</h2>
              <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
          {hasCtaButton && (
            <div className="cta">
              <div className="btn-container">
                <a
                  className={classNames('btn', ctaButtonColor.toLowerCase())}
                  href={ctaButtonUrl}
                  target={ctaButtonTarget}
                >
                  {ctaButtonText}
                </a>
              </div>
            </div>
          )}
        </div>
      </section>
    </ConditionalWrapper>
  );
};

export default FeaturedCardComponent;
