import React, { useEffect, useRef, useState } from 'react';

const Statistic = ({ title, value, icon }) => {
  const statistic = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [hasCounted, setHasCounted] = useState(false);
  const [symbol, setSymbol] = useState('');

  const formatter = (num) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' }
    ];

    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup.findLast((suffix) => num >= suffix.value);
    return item
      ? {
          num: (num / item.value).toFixed(1).replace(regexp, ''),
          symbol: item.symbol
        }
      : { num: 0, symbol: '' };
  };

  useEffect(() => {
    const { current } = statistic;

    if (!current) return undefined;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setIsVisible(entry.isIntersecting));
    });
    observer.observe(current);

    return () => observer.unobserve(current);
  }, []);

  useEffect(() => {
    const { current } = statistic;

    if (!current) return;

    const { num, symbol: itemSymbol } = formatter(value - 1);
    const { num: finalNum, symbol: finalSymbol } = formatter(value);

    if (isVisible && !hasCounted) {
      setTimeout(() => {
        setSymbol(finalSymbol);
        current.style.setProperty('--final-value', finalNum);
        current.style.setProperty('transition', 'none');
        current.classList.add('counting');
      }, 3000);

      setSymbol(itemSymbol);
      current.style.setProperty('transition', '--stat-value 3s cubic-bezier(0.5, 0.75, 0.5, 1)');
      current.style.setProperty('--final-value', num);
      current.classList.add('counting');

      setHasCounted(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, value]);

  return (
    <div className="statistic" ref={statistic}>
      <span className="statistic-value">
        {symbol}
        {icon}
      </span>
      <span className="statistic-title">{title}</span>
    </div>
  );
};

export default Statistic;
