import React from 'react';
import { Element } from 'react-scroll';

import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import { getSectionClassNames } from '@/utilities/component-color';

import ContentDivider from '@/components/widgets/shared/ContentDivider';
import StateSelectorComponent from './StateSelectorComponent';

const ProductNetworkAvailabilityComponent = ({
  title,
  hasContentDivider,
  prettyUrlTitle,
  searchHeader,
  states,
  componentBackgroundColor
}) => (
  <section
    className={`${getSectionClassNames(componentBackgroundColor)} section-network-availability`}
  >
    <ConditionalWrapper
      condition={prettyUrlTitle}
      wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
    >
      <div className="container-fluid">
        {title && (
          <div className="global-props">
            <h2 className="section-title">{title}</h2>
          </div>
        )}
        {states && (
          <StateSelectorComponent
            {...{
              searchHeader,
              states,
              showContentDividerInitialState: false,
              showDataTableContentDivider: false
            }}
          />
        )}
        {hasContentDivider && <ContentDivider />}
      </div>
    </ConditionalWrapper>
  </section>
);

export default ProductNetworkAvailabilityComponent;
