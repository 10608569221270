import React, { useState } from 'react';
import classNames from 'classnames';
import ContentDivider from '@/components/widgets/shared/ContentDivider';
import StateSelector from './StateSelector';

const StateSelectorComponent = ({
  initialState,
  searchHeader,
  states,
  showContentDividerInitialState,
  showDataTableContentDivider
}) => {
  const [showContentDivider, setShowContentDivider] = useState(showContentDividerInitialState);

  if (!states) return null;

  return (
    <div className={classNames('container-fluid', { 'has-divider': showContentDivider })}>
      <div className="section-gutter">
        <StateSelector
          {...{
            initialState,
            searchHeader,
            states,
            setShowContentDivider,
            showDataTableContentDivider
          }}
        />
      </div>
      {showContentDivider && <ContentDivider />}
    </div>
  );
};

export default StateSelectorComponent;
