import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

const createHandleMouseMove = (current) => (e) => {
  const rect = current.getBoundingClientRect();
  current.style.setProperty('--x', `${e.clientX - rect.left}px`);
  current.style.setProperty('--y', `${e.clientY - rect.top}px`);
};

const Wrapper = ({ children }) => <span className="wrapper">{children}</span>;

export const FillFromMouseButton = ({ className = '', children, ...passThroughProps }) => {
  const button = useRef(null);

  useEffect(() => {
    const { current } = button;
    const handleMouseMove = createHandleMouseMove(button.current);

    current?.addEventListener('mousemove', handleMouseMove);

    return () => {
      current?.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <button
      type="button"
      ref={button}
      className={classNames('no-style fill-animation', className)}
      data-hoverable
      {...passThroughProps}
    >
      <Wrapper>{children}</Wrapper>
    </button>
  );
};

export const FillFromMouseLink = ({ className = '', children, href }) => {
  const anchor = useRef(null);

  useEffect(() => {
    const { current } = anchor;
    const handleMouseMove = createHandleMouseMove(current);

    current?.addEventListener('mousemove', handleMouseMove);

    return () => {
      current?.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <a href={href} ref={anchor} className={classNames('fill-animation', className)} data-hoverable>
      <Wrapper>{children}</Wrapper>
    </a>
  );
};

export const FillUpLink = ({ className = '', children, ...props }) => (
  <a className={classNames('fill-up-link', className)} data-hoverable {...props}>
    {children}
  </a>
);
