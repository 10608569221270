/* global $ */

(() => {
  if (!global.kentico) {
    return;
  }
  const KEY_UP_DEBOUNCE_DURATION = 800;
  const removeScriptTags = (html) => html.replaceAll(/<script(?!.*?src).*?>[^]*?<\/script>/g, '');
  const getFormElement = (html) => {
    const parser = new DOMParser();
    const htmlFragment = parser.parseFromString(html, 'text/html');
    const form = htmlFragment.querySelector('form');
    if (form) {
      form.onsubmit = () => null;
      const formParent = htmlFragment.querySelector('form')?.parentElement;
      return formParent ?? htmlFragment.firstChild;
    }
    return htmlFragment.firstChild;
  };
  const createRequest = (form, formData, onResponse) => {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('load', onResponse);
    xhr.open('POST', form.action);
    xhr.send(formData);
  };
  const setCaretPosition = (input, selectionStart, selectionEnd) => {
    if (selectionStart === null && selectionEnd === null) {
      return;
    }
    if (input.setSelectionRange) {
      input.setSelectionRange(selectionStart, selectionEnd);
    }
  };
  const debounce = (func, wait, immediate = false) => {
    let timeout;
    return (...args) => {
      const context = this;
      const later = () => {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait || 200);
      if (callNow) {
        func.apply(context, args);
      }
    };
  };
  const replaceForm = (event, form) => {
    let replaceTarget = form.getAttribute('data-ktc-ajax-update');
    replaceTarget = replaceTarget.startsWith('#') ? replaceTarget.substring(1) : replaceTarget;
    const response = event.target.response || event.target.responseText;
    const html = removeScriptTags(response);
    const formElement = getFormElement(html);
    document.getElementById(replaceTarget)?.replaceWith(formElement);
    // eslint-disable-next-line no-use-before-define
    registerEventListeners({
      formId: formElement.firstChild.id,
      targetAttributeName: 'data-ktc-ajax-update',
      unobservedAttributeName: 'data-ktc-notobserved-element'
    });
  };
  const submitForm = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const onResponse = async (e) => {
      const contentType = e.target.getResponseHeader('Content-Type');
      if (contentType.indexOf('application/json') === -1) {
        const currentScrollPosition = $(window).scrollTop();
        replaceForm(e, form);
        $(window).scrollTop(currentScrollPosition);
      } else {
        const json = JSON.parse(e.target.response);
        window.location.href = json.redirectTo;
      }
    };
    createRequest(form, formData, onResponse);
  };
  const updateForm = (form, nextFocusElement = undefined) => {
    if (!form) {
      return;
    }
    // If form is not updatable then do nothing
    const elementIdSelector = form.getAttribute('data-ktc-ajax-update');
    if (!elementIdSelector) {
      return;
    }
    $(form).find("input[type='submit']").attr('disabled', 'true');
    form.updating = true; // eslint-disable-line no-param-reassign
    const formData = new FormData(form);
    formData.append('kentico_update_form', 'true');
    const focus = nextFocusElement || document.activeElement;
    const onResponse = (event) => {
      if (!event.target.response.data) {
        let selectionStart = null;
        let selectionEnd = null;
        if (
          focus &&
          (focus.type === 'text' ||
            focus.type === 'search' ||
            focus.type === 'password' ||
            focus.type === 'tel' ||
            focus.type === 'url')
        ) {
          selectionStart = focus.selectionStart;
          selectionEnd = focus.selectionEnd;
        }
        const currentScrollPosition = $(window).scrollTop();
        replaceForm(event, form);
        $(window).scrollTop(currentScrollPosition);
        $(form).find("input[type='submit']").attr('disabled', null);
        if (focus.id) {
          const newInput = document.getElementById(focus.id);
          if (newInput) {
            newInput.focus();
            setCaretPosition(newInput, selectionStart, selectionEnd);
          }
        }
      }
    };
    createRequest(form, formData, onResponse);
  };
  const registerEventListeners = (config) => {
    if (
      !config ||
      !config.formId ||
      !config.targetAttributeName ||
      !config.unobservedAttributeName
    ) {
      throw new Error('Invalid configuration passed.');
    }
    const writeableTypes = ['email', 'number', 'password', 'search', 'tel', 'text', 'time'];
    const observedForm = document.getElementById(config.formId);
    if (!(observedForm && observedForm.getAttribute(config.targetAttributeName))) {
      return;
    }
    for (let i = 0; i < observedForm.length; i += 1) {
      const observedFormElement = observedForm.elements[i];
      const handleElement =
        !observedFormElement.hasAttribute(config.unobservedAttributeName) &&
        observedFormElement.type !== 'submit';
      if (handleElement) {
        const isWriteableElement =
          (observedFormElement.tagName === 'INPUT' &&
            writeableTypes.indexOf(observedFormElement.type) !== -1) ||
          observedFormElement.tagName === 'TEXTAREA';
        if (isWriteableElement) {
          // @ts-expect-error Setting arbitrary property
          observedFormElement.previousValue = observedFormElement.value;
          observedFormElement.addEventListener(
            'keyup',
            debounce((e) => {
              setTimeout(() => {
                if (!observedForm.updating && e.target.previousValue !== e.target.value) {
                  observedForm.keyupUpdate = true;
                  updateForm(observedForm, e.target);
                }
              }, 0);
            }, KEY_UP_DEBOUNCE_DURATION)
          );
          observedFormElement.addEventListener('blur', (e) => {
            setTimeout(() => {
              if (
                !observedForm.updating &&
                // @ts-expect-error Setting arbitrary property
                e.target.previousValue !== e.target.value
              ) {
                updateForm(observedForm, e.relatedTarget);
              }
            }, 0);
          });
        }
        observedFormElement.addEventListener('change', () => {
          setTimeout(() => {
            if (!observedForm.updating) {
              updateForm(observedForm);
            }
          }, 0);
        });
      }
    }
  };
  global.kentico.updatableFormHelper.submitForm = submitForm;
  global.kentico.updatableFormHelper.updateForm = updateForm;
  global.kentico.updatableFormHelper.registerEventListeners = registerEventListeners;
})();
