import React, { useEffect, useMemo, useRef, Children, useCallback } from 'react';

const ScrollContainer = ({ children, blockHeight }) => {
  // Starting offset of horizontal scroll, increase to begin scrolling earlier
  const startOffset = 135;
  // Ending offset of horizontal scroll, decrease to scroll further
  const endOffset = 685;
  // max width of .container-fluid + 20px padding
  const contentWidth = 1320;

  const wrapper = useRef(null);
  const horizontalScroll = useRef(null);
  const blockCount = useMemo(() => Children.count(children), [children]);
  const totalBlockHeight = useMemo(() => blockHeight * blockCount, [blockCount, blockHeight]);
  const totalGap = useMemo(() => 20 * (blockCount - 1), [blockCount]);
  const containerWidth = useMemo(() => totalBlockHeight + totalGap, [totalBlockHeight, totalGap]);
  const containerHeight = useMemo(
    () => totalBlockHeight + totalGap - blockHeight,
    [totalBlockHeight, totalGap, blockHeight]
  );

  const handleScroll = useCallback(() => {
    if (!wrapper.current || !horizontalScroll.current) return;

    // Get element's position relative to the top of the DOM
    // and adjust starting scroll position
    const boundingClientTop = wrapper.current.parentElement?.getBoundingClientRect().top ?? 0;
    const offsetTop = boundingClientTop + window.scrollY - startOffset;

    // Calculate percentage to scroll blocks but don't go negative
    let percentage = (window.scrollY - offsetTop) / blockHeight;
    percentage = Math.max(percentage, 0);

    // If window is smaller than content width,
    // we need to scroll further, faster
    let finalOffset = endOffset;
    if (window.innerWidth < contentWidth) {
      percentage *= contentWidth / window.innerWidth;
      finalOffset = endOffset - contentWidth + window.innerWidth;
    }

    // Scroll horizontally based on percentage,
    // but don't exceed the container height (minus some end offset)
    const offset = Math.min(percentage * blockHeight, containerHeight - finalOffset);

    horizontalScroll.current.style.transform = `translate3d(${-offset}px, 0, 0)`;
  }, [blockHeight, containerHeight]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div
      style={{
        '--container-height': `${containerHeight}px`,
        '--container-width': `${containerWidth}px`,
        '--block-height': `${blockHeight}px`
      }}
      className="scroll-container"
    >
      <div className="scroll-container-wrapper" ref={wrapper}>
        <div className="scroll-container-horizontal-scroll" ref={horizontalScroll}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ScrollContainer;
