import React from 'react';

const BusinessUnitItem = ({
  getNewsroomData,
  isActive,
  modifyFilters,
  setFilters,
  title,
  value
}) => (
  <li>
    <button
      onClick={() => {
        const filters = modifyFilters('businessunittitle', value, true);
        setFilters(filters);
        getNewsroomData(1, filters, false);
      }}
      value={value}
      type="button"
      className={`btn-filter ${isActive ? 'active' : ''}`}
    >
      {title}
    </button>
  </li>
);

export default BusinessUnitItem;
