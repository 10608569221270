import React from 'react';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';

const FeaturedCard = ({ altText, contentType, link, title, /* url, */ thumbnailImage }) => {
  const hasImage = thumbnailImage && thumbnailImage.url;

  return (
    <div className="card featured">
      <div className="content">
        {/* <a className="section-header top" href={`${url}?contenttype=${contentType}`}>
          {contentType}
        </a> */}
        <a href={link} title={`Link to ${title}`}>
          <div className={`inner-image${thumbnailImage ? '' : ' no-thumbnail'}`}>
            {hasImage && <LazyLoadImg src={thumbnailImage} alt={altText || `${title} thumbnail`} />}
          </div>

          <div className="inner-content">
            <span className="section-header top">{contentType}</span>
            <p className="title">{title}</p>
            <span className="section-header link">Read More</span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default FeaturedCard;
