import React from 'react';
import SmallHeroSlide from './SmallHeroSlide';

const SmallHeroWidget = ({ smallHeroSlide }) => (
  <section className="hero hero-small">
    <SmallHeroSlide heroSlide={smallHeroSlide} />
  </section>
);

export default SmallHeroWidget;
