import React from 'react';

import { Element } from 'react-scroll';
import GlobalComponentContainer from '@/components/widgets/GlobalComponentContainer';
import ContentDivider from '@/components/widgets/shared/ContentDivider';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import { getSectionClassNames } from '@/utilities/component-color';
import Faq from './Faq';

const FaqComponent = ({
  faqs,
  hasContentDivider,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title,
  upArrow,
  downArrow,
  componentBackgroundColor
}) => (
  <section className={getSectionClassNames(componentBackgroundColor)}>
    <div className="container-fluid">
      <div className="faq-component section-gutter">
        <ConditionalWrapper
          condition={prettyUrlTitle}
          wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
        >
          {title && (
            <GlobalComponentContainer
              {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
            />
          )}
          <div className="faq-container">
            {faqs.map((faq) => (
              <Faq
                answer={faq.answer}
                faqs={faq.children}
                key={faq.question}
                prettyUrlTitle={faq.prettyUrlTitle}
                question={faq.question}
                upArrow={upArrow}
                downArrow={downArrow}
              />
            ))}
          </div>
        </ConditionalWrapper>
      </div>
      {hasContentDivider && <ContentDivider />}
    </div>
  </section>
);

export default FaqComponent;
