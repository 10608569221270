import React from 'react';
import { Element } from 'react-scroll';
import { getSectionClassNames } from '@/utilities/component-color';
import GlobalComponentContainer from '@/components/widgets/GlobalComponentContainer';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import FeaturedCta from '@/components/widgets/featuredCtaGroup/FeaturedCta';

const FeaturedCtaGroupComponent = ({
  componentBackgroundColor,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title,
  featuredCtas
}) => (
  <section className={getSectionClassNames(componentBackgroundColor)}>
    <div className="container-fluid">
      <div className="featured-cta-group section-gutter">
        <ConditionalWrapper
          condition={prettyUrlTitle}
          wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
        >
          {title && (
            <GlobalComponentContainer
              {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
            />
          )}
          <div className="wrapper">
            {featuredCtas.map((cta) => (
              <FeaturedCta cta={cta} key={cta.ctaName} />
            ))}
          </div>
        </ConditionalWrapper>
      </div>
    </div>
  </section>
);

export default FeaturedCtaGroupComponent;
