import React from 'react';

const WhiteLabelNavigationContentComponent = ({ whiteLabelNavigationItems }) => (
  <section className="section-white">
    <div className="gh-background row">
      {whiteLabelNavigationItems.map((item) => (
        <div className="mobile-device-cls">
          <a href={item.path} target={item.target} className="link-color col-md-1">
            {item.documentName}
          </a>
          <span className="slash-color">|</span>
        </div>
      ))}
    </div>
  </section>
);

export default WhiteLabelNavigationContentComponent;
