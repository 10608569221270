import React from 'react';
import { Element } from 'react-scroll';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import ContentDivider from '@/components/widgets/shared/ContentDivider';
import { getSectionClassNames } from '@/utilities/component-color';

const LegalDisclaimerComponent = ({
  legalDisclaimer,
  prettyUrlTitle,
  hasContentDivider,
  componentBackgroundColor
}) => (
  <section className={getSectionClassNames(componentBackgroundColor)}>
    <ConditionalWrapper
      condition={prettyUrlTitle}
      wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
    >
      <div className="container-fluid">
        <div className="section-legal">
          <div className="content" dangerouslySetInnerHTML={{ __html: legalDisclaimer }} />
        </div>
        {hasContentDivider && <ContentDivider />}
      </div>
    </ConditionalWrapper>
  </section>
);

export default LegalDisclaimerComponent;
