import React from 'react';
import { Element } from 'react-scroll';

import ContentDivider from '@/components/widgets/shared/ContentDivider';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';

import arrowLeft from 'images/icons/layout/Arrow-Left-Blue.svg';
import arrowRight from 'images/icons/layout/Arrow-Right-Blue.svg';
import { PREVIOUS, NEXT } from '@/constants/expansion-titles';

const ProductNavigationComponent = ({
  hasContentDivider,
  nextProduct,
  prettyUrlTitle,
  previousProduct
}) => (
  <section className="section-white">
    <div className="container-fluid">
      <ConditionalWrapper
        condition={prettyUrlTitle}
        wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
      >
        <div className="section-product-navigation">
          {previousProduct && (
            <div className="button prev">
              <a href={previousProduct.url} title={`Go to ${previousProduct.title}`}>
                <span>
                  <img src={arrowLeft} alt="Back Arrow" />
                  {PREVIOUS}
                </span>
                <h3>{previousProduct.title}</h3>
              </a>
            </div>
          )}
          {nextProduct && (
            <div className="button next">
              <a href={nextProduct.url} title={`Go to ${nextProduct.title}`}>
                <span>
                  {NEXT}
                  <img src={arrowRight} alt="Next Arrow" />
                </span>
                <h3>{nextProduct.title}</h3>
              </a>
            </div>
          )}
        </div>
        {hasContentDivider && <ContentDivider />}
      </ConditionalWrapper>
    </div>
  </section>
);

export default ProductNavigationComponent;
