import React, { useRef } from 'react';

import {
  SLIDE_CONTENT_POSITION_CENTER,
  SLIDE_CONTENT_POSITION_LEFT,
  SLIDE_CONTENT_POSITION_RIGHT
} from '@/constants/slide-content-positions';

import CtaButton from '@/components/shared/ctaButton/CtaButton';
import { useCssVariables } from '@/utilities';

const getJustifyContentValue = (contentPosition) => {
  switch (contentPosition) {
    case SLIDE_CONTENT_POSITION_CENTER:
      return 'center';
    case SLIDE_CONTENT_POSITION_RIGHT:
      return 'flex-end';
    case SLIDE_CONTENT_POSITION_LEFT:
    default:
      return 'flex-start';
  }
};

const getTextAlignValue = (contentPosition) => {
  switch (contentPosition) {
    case SLIDE_CONTENT_POSITION_CENTER:
      return 'center';
    case SLIDE_CONTENT_POSITION_RIGHT:
      return 'right';
    case SLIDE_CONTENT_POSITION_LEFT:
    default:
      return 'left';
  }
};

const WhiteLabelSmallHeroBanner = ({ heroSlide }) => {
  const slideRef = useRef(null);
  const { ctaButton } = heroSlide;
  const { hasCtaButton, hasSecondCtaButton } = ctaButton;

  useCssVariables(slideRef, {
    '--background-color': `${heroSlide.backgroundColor || 'unset'}`,
    '--text-color': `${heroSlide.textColor || 'unset'}`,
    '--slide-text-color': `${heroSlide.textColor || 'unset'}`,
    '--slide-justify-content': getJustifyContentValue(heroSlide.textPosition),
    '--slide-text-align': getTextAlignValue(heroSlide.contentPosition),
    '--button-text-color': `${ctaButton.textColor || 'unset'}`,
    '--button-justify-content': getJustifyContentValue(ctaButton.position)
  });

  return (
    <div className="item" ref={slideRef}>
      <div className="color-wrapper">
        <div className="content">
          <div className="container-fluid">
            <div className="slide-content">
              <div className="inner-content">
                <h1>
                  <span dangerouslySetInnerHTML={{ __html: heroSlide.title }} />
                </h1>
                <p dangerouslySetInnerHTML={{ __html: heroSlide.eyebrowText }} />
              </div>
            </div>
            {hasCtaButton && (
              <div className="inner-cta button">
                <CtaButton {...ctaButton}>{ctaButton.text}</CtaButton>
                {hasSecondCtaButton && (
                  <CtaButton
                    className="second"
                    backgroundColor={ctaButton.secondBackgroundColor}
                    path={ctaButton.secondPath}
                    target={ctaButton.secondTarget}
                    textColor={ctaButton.secondTextColor}
                    buttonColor={ctaButton.secondButtonColor}
                    overrideButtonColor={ctaButton.secondOverrideButtonColor}
                  >
                    {ctaButton.secondText}
                  </CtaButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhiteLabelSmallHeroBanner;
