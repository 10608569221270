const initializeSortables = (root) => {
  const sortables = root.querySelectorAll('.sortable-container:not([data-initialized="true"])');

  sortables.forEach((sortable) => {
    sortable.setAttribute('data-initialized', 'true');
    // eslint-disable-next-line
    new SortableList(sortable);
  });
};

(async () => {
  if (typeof window === 'undefined') return;

  document.addEventListener('DOMContentLoaded', () => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof Element) {
              initializeSortables(node);
            }
          });
        }
      });
    });

    observer.observe(document.documentElement, { childList: true, subtree: true });
    initializeSortables(document);
  });
})();

class SortableList {
  constructor(sl) {
    this.sortableList = sl.querySelector('.sortable-list');
    this.hiddenInput = sl.querySelector('.sort-order-hidden-input');
    this.listItems = sl.querySelectorAll('.sortable-list-item');

    this.listItems.forEach((item) => {
      item.addEventListener('keydown', (event) => {
        if (event.key !== 'Tab') {
          event.preventDefault();
          event.stopPropagation();
        }

        if (event.key === ' ') {
          if (item.getAttribute('selected') === 'true') {
            item.setAttribute('selected', false);
          } else {
            item.setAttribute('selected', true);
          }
        }

        if (
          event.key === 'ArrowDown' &&
          item.getAttribute('selected') === 'true' &&
          item.nextElementSibling
        ) {
          item.parentNode.insertBefore(item.nextElementSibling, item);
          item.focus();
          item.setAttribute('selected', true);
          this.updateHiddenInput({ data: { newContainer: item.parentNode } });
        }

        if (
          event.key === 'ArrowUp' &&
          item.getAttribute('selected') === 'true' &&
          item.previousElementSibling
        ) {
          item.parentNode.insertBefore(item, item.previousElementSibling);
          item.focus();
          item.setAttribute('selected', true);
          this.updateHiddenInput({ data: { newContainer: item.parentNode } });
        }
      });

      item.addEventListener('blur', () => {
        item.setAttribute('selected', false);
      });
    });

    this.initializeSortable();
  }

  initializeSortable = async () => {
    const { Sortable, Plugins } = await import('@shopify/draggable');

    const sortable = new Sortable(this.sortableList, {
      draggable: '.sortable-list-item',
      mirror: {
        appendTo: '.sortable-list',
        constrainDimensions: true
      },
      plugins: [Plugins.SortAnimation],
      sortAnimation: {
        duration: 200,
        easingFunction: 'ease-in-out'
      }
    });

    sortable.on('sortable:stop', (event) => {
      this.updateHiddenInput(event);
    });

    this.initializeList();
  };

  initializeList = () => {
    this.hiddenInput.value = Array.from(this.listItems)
      .map((item) => item.getAttribute('data-value'))
      .join('\r\n');
  };

  updateHiddenInput = (event) => {
    const sortedItems = Array.from(event.data.newContainer.children)
      .filter(
        (item) =>
          !item.classList.contains('draggable--original') &&
          !item.classList.contains('draggable-mirror')
      )
      .map((item) => item.getAttribute('data-value'));

    this.hiddenInput.value = sortedItems.join('\r\n');
  };
}
