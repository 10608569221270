import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import arrowDown from 'images/icons/layout/Arrow-Down-Blue.svg';
import MaterialListing from '../materialLibrary/MaterialListing';

const WhiteLabelListing = ({ categories }) => {
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [categoriesToDisplay, setCategoriesToDisplay] = useState(categories);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const filters = categories.map((category) => ({
    name: category.name,
    displayName: category.displayName
  }));

  useEffect(() => {
    if (selectedCategory === 'All') {
      setCategoriesToDisplay(categories);
    } else {
      setCategoriesToDisplay(categories.filter((category) => category.name === selectedCategory));
    }
  }, [selectedCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="white-label-category-selector">
        <p>Category:</p>
        <div className="white-label-category-selector-select">
          <select
            className="custom"
            onClick={() => setIsFilterExpanded(!isFilterExpanded)}
            onBlur={() => setIsFilterExpanded(false)}
            onChange={(selected) => setSelectedCategory(selected.target.value)}
          >
            <option value="All">All Categories</option>
            {filters.map((filter) => (
              <option value={filter.name} key={filter.name}>
                {filter.displayName}
              </option>
            ))}
          </select>
          <img
            src={arrowDown}
            className={classNames('chevron', { expanded: isFilterExpanded })}
            alt="Chevron Arrow"
          />
        </div>
      </div>
      <div className="white-label-categories">
        {categoriesToDisplay.map((category) => (
          <div
            className="white-label-category"
            key={`${category.name}|${category.materials[0].formID}`}
          >
            <p className="white-label-category-title">{category.displayName}</p>
            <div className="white-label-category-materials">
              {category.materials.map((material) => (
                <MaterialListing
                  {...material}
                  key={`${material.materialTitle}|${material.formID}`}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default WhiteLabelListing;
