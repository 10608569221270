import React from 'react';

const ParagraphItem = ({ description, title }) => (
  <div className="item">
    {title && <span className="eyebrow-text">{title}</span>}
    <div className="inner-content" dangerouslySetInnerHTML={{ __html: description }} />
  </div>
);

export default ParagraphItem;
