import React from 'react';
import ArrowLeft from 'images/icons/layout/Arrow-Left-Black.svg';
import ArrowRight from 'images/icons/layout/Arrow-Right-Black.svg';

const Pager = ({ search, searchText, currentPage, pagerInfo }) => {
  const showPrevious = currentPage !== 1;
  const showNext = currentPage !== pagerInfo.totalPages;
  const activePages = [];
  const hide = pagerInfo.totalPages === 1;

  if (hide) return null;

  for (let i = pagerInfo.startPage; i <= pagerInfo.endPage; i += 1) {
    if (i > 1 && i < pagerInfo.totalPages) {
      activePages.push(i);
    }
  }

  return (
    <div className="pager">
      {showPrevious && (
        <button
          type="button"
          className="btn-no-style pager-prev"
          onClick={() => search(searchText, currentPage - 1)}
        >
          <img src={ArrowLeft} className="chevron" alt="Previous Page" />
        </button>
      )}
      <button
        type="button"
        className={`btn-no-style ${currentPage === 1 ? 'current-page' : ''}`}
        onClick={() => search(searchText, 1)}
      >
        1
      </button>
      {pagerInfo.startPage > 2 && <span>...</span>}
      {activePages.map((page) => (
        <button
          type="button"
          key={page}
          className={`btn-no-style ${currentPage === page ? 'current-page' : ''}`}
          onClick={() => search(searchText, page)}
        >
          {page}
        </button>
      ))}
      {pagerInfo.endPage < pagerInfo.totalPages - 1 && <span>...</span>}
      <button
        type="button"
        className={`btn-no-style ${currentPage === pagerInfo.totalPages ? 'current-page' : ''}`}
        onClick={() => search(searchText, pagerInfo.totalPages)}
      >
        {pagerInfo.totalPages}
      </button>
      {showNext && (
        <button
          type="button"
          className="btn-no-style pager-next"
          onClick={() => search(searchText, currentPage + 1)}
        >
          <img src={ArrowRight} className="chevron" alt="Next Page" />
        </button>
      )}
    </div>
  );
};

export default Pager;
