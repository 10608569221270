import React from 'react';
import ParagraphItem from '@/components/widgets/shared/ParagraphItem';

const ProductsServicesTileExpansion = ({ isSlider, overview, selectProduct, closeIcon }) => (
  <div className={isSlider ? 'tile-expansion' : 'tile-expansion no-slider'}>
    <button
      type="button"
      onClick={() => selectProduct()}
      className={isSlider ? 'close-button' : 'close-button no-slider'}
    >
      <img src={closeIcon} alt="Close" />
    </button>
    <div className="content">
      <ParagraphItem title="" description={overview} />
    </div>
  </div>
);

export default ProductsServicesTileExpansion;
