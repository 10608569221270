import React from 'react';
import classNames from 'classnames';

const FeaturedCta = ({ cta }) => (
  <a
    className={classNames('featured-cta', cta.iconDisplayLocation, cta.color.toLowerCase())}
    href={cta.link}
    target={cta.target}
    rel={cta.target === '_blank' ? 'noopener noreferrer' : ''}
    key={cta.ctaName}
  >
    {cta.icon && <img src={cta.icon} alt="" height="32" width="32" />}
    {cta.ctaName}
  </a>
);

export default FeaturedCta;
