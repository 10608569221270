import React from 'react';
import Statistic from './Statistic';

const StatsCycleGroupComponent = ({ statsCycleGroup }) => {
  const { header, description, statistics } = statsCycleGroup;

  return (
    <section className="stats-cycle-section container-fluid">
      <div className="stats-cycle-info">
        <h2>{header}</h2>
        <p>{description}</p>
      </div>
      <div className="stats-cycle-statistics">
        {statistics.map((statistic) => (
          <Statistic
            key={statistic.statName}
            title={statistic.statName}
            value={statistic.statValue}
            icon={statistic.icon}
          />
        ))}
      </div>
    </section>
  );
};

export default StatsCycleGroupComponent;
