import { apiGetService, apiPostService } from './baseApi.service';

function getSearchSuggestionsData(
  searchText,
  microSitePath,
  useHighlights = false,
  top = 5,
  useFuzzyMatching = false
) {
  return (async () => {
    let url = `/api/search/suggest?searchText=${searchText}&useHighlights=${useHighlights}&top=${top}&useFuzzyMatching=${useFuzzyMatching}`;

    if (microSitePath) {
      url += `&microSitePath=${microSitePath}`;
    }

    return apiGetService(url);
  })();
}

export function getSearchSuggestions(
  minimumCharacters,
  searchText,
  microSitePath,
  useHighlights = false,
  top = 5,
  useFuzzyMatching = false
) {
  if (searchText.length < minimumCharacters) {
    return null;
  }

  return getSearchSuggestionsData(searchText, microSitePath, useHighlights, top, useFuzzyMatching);
}

export function getSearch(query, page, itemsPerPage, businessUnitTitle, filter, microSitePath) {
  return (async () => {
    let url = `/api/search/search?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (query) {
      url += `&query=${query}`;
    }

    if (businessUnitTitle) {
      url += `&businessUnitTitle=${businessUnitTitle}`;
    }

    if (filter) {
      url += `&${filter.key}=${filter.value}`;
    }
    if (microSitePath) {
      url += `&microSitePath=${microSitePath}`;
    }
    return apiGetService(url);
  })();
}

export function submitClick(item) {
  return (async () => apiPostService('/api/search/submitClick', item))();
}
