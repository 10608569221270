import { useEffect, useState } from 'react';

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    const onChange = (e) => setMatches(e.matches);

    setMatches(matchMedia.matches);

    matchMedia.addEventListener('change', onChange);

    return () => {
      matchMedia.removeEventListener('change', onChange);
    };
  }, [query]);

  return matches;
};

export default useMediaQuery;
