import React, { useRef } from 'react';
import classNames from 'classnames';
import { useCssVariables } from '@/utilities';

const TestimonialDesktop = ({ altText, author, colorScheme, icon, image, quote }) => {
  const testimonialImageRef = useRef(null);

  useCssVariables(testimonialImageRef, {
    '--background-image': `url("${image.url}")`
  });

  return (
    <div className={classNames('testimonial br-md-flx', colorScheme.toLowerCase())}>
      {image.url && <div ref={testimonialImageRef} className="testimonial-image" />}
      <div className="testimonial-body">
        {icon && <img className="icon-start" src={icon} alt={altText || 'icon'} />}
        <h2 dangerouslySetInnerHTML={{ __html: quote }} />
        <h3 dangerouslySetInnerHTML={{ __html: author }} />
        {icon && <img className="icon-end" src={icon} alt={altText || 'icon'} />}
      </div>
    </div>
  );
};

export default TestimonialDesktop;
