import React from 'react';
import SearchIcon from 'images/icons/layout/Search.svg';
import NavigationMenuItem from './NavigationMenuItem';

const DesktopMenu = ({ logoInverse, searchUrl, headerNavigationItems, utilityNavigationItems }) => (
  <nav className="desktop-navigation">
    <div className="navigation-list-wrapper">
      <a className="main-logo" href="/" aria-label="Go to home page">
        <img src={logoInverse?.directUrl} alt="" />
      </a>
      <ul className="navigation-list main-navigation">
        {headerNavigationItems.map((item) => (
          <NavigationMenuItem item={item} key={item.nodeId} />
        ))}
      </ul>
      <ul className="navigation-list utility-navigation">
        {utilityNavigationItems.map((item) => (
          <NavigationMenuItem item={item} key={item.nodeId} />
        ))}
        <NavigationMenuItem
          item={{
            nodeId: 'search',
            icon: SearchIcon,
            name: 'Search',
            path: searchUrl,
            navigationChildren: []
          }}
        />
      </ul>
    </div>
  </nav>
);

export default DesktopMenu;
