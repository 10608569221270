import React from 'react';
import HomepageHeroSlide from './HomepageHeroSlide';

const HomepageHeroWidget = ({ homepageHeroSlide }) => (
  <section className="homepage-hero">
    <HomepageHeroSlide slide={homepageHeroSlide} />
  </section>
);

export default HomepageHeroWidget;
