import React, { useState } from 'react';
import CCPABanner from './CCPABanner';
import { FillUpLink } from '../FillAnimation';

const Footer = ({
  address,
  copyright,
  footerColumns,
  socialIcons,
  logoInverse,
  ccpaBanner,
  isWhiteLabel
}) => {
  const [hasCCPABanner, setHasCCPABanner] = useState(ccpaBanner);

  const closeCCPABanner = () => {
    setTimeout(() => {
      setHasCCPABanner(false);
    }, 300);
  };

  return (
    <footer>
      <div className="footer-container">
        <div className="footer-column">
          <a className="main-logo" href="/" aria-label="Go to home page">
            <img alt="" src={logoInverse?.directUrl} />
          </a>
          {!isWhiteLabel && (
            <>
              <p>{address}</p>
              <p>{copyright}</p>
              <div className="social-icons">
                {socialIcons?.map((social) => (
                  <a
                    key={social.name}
                    href={social.url}
                    target={social.target ?? '_blank'}
                    title={`Go to ${social.name}`}
                  >
                    <img className="social-icon" src={social.icon} alt={social.name} />
                  </a>
                ))}
              </div>
            </>
          )}
        </div>
        {!isWhiteLabel &&
          footerColumns?.map((column) => (
            <div className="footer-column" key={column.title}>
              <h3>{column.title}</h3>
              <ul>
                {column.items.map((item) => (
                  <li key={item.name}>
                    <FillUpLink href={item.path} title={`Go to ${item.name}`}>
                      {item.name}
                    </FillUpLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
      {hasCCPABanner && <CCPABanner ccpaBanner={ccpaBanner} closeCCPABanner={closeCCPABanner} />}
    </footer>
  );
};

export default Footer;
