import React from 'react';

const NavigationPanel = ({ children, isOpen, ...passThroughProps }) => (
  <>
    <div
      className="navigation-panel"
      data-is-open={isOpen}
      aria-hidden={!isOpen}
      {...passThroughProps}
    >
      <div className="wrapper">{children}</div>
    </div>
    <div className="navigation-panel-overlay" data-is-open={isOpen} />
  </>
);

export default NavigationPanel;
