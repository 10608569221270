import { useLayoutEffect, useRef, useState } from 'react';

/**
 * Custom hook that uses the ResizeObserver API to observe changes to the size of an HTML element.
 *
 * @param {function(ResizeObserverEntry):void} [onResize] - Optional callback function that is invoked when the element is resized.
 * @returns {readonly [React.MutableRefObject<T | null>, ResizeObserverEntry | null]} A tuple containing a ref to the observed element and the latest ResizeObserverEntry.
 */
const useResizeObserver = (onResize) => {
  /** @type {React.MutableRefObject<T | null>} */
  const ref = useRef(null);
  /** @type {[ResizeObserverEntry | null, React.SetStateAction<ResizeObserverEntry>]} */
  const [entry, setEntry] = useState(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    /**
     * Handles the resize events and updates the state and optional callback.
     *
     * @param {ResizeObserverEntry[]} entries - An array of ResizeObserverEntry objects.
     */
    const handleResize = (entries) => {
      if (entries && entries.length > 0) {
        setEntry(entries[0]);
        if (onResize) {
          onResize(entries[0]);
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  }, [onResize]);

  return /** @type {const} */ [ref, entry];
};

export default useResizeObserver;
