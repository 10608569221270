import React from 'react';
import Checkbox from './Checkbox';

const FilterSection = ({ getNewsroomData, modifyFilters, setFilters, title, type, values }) => (
  <div className="form-group">
    <span className="section-header">{title}</span>
    {values.map((item) => (
      <Checkbox
        getNewsroomData={getNewsroomData}
        isSelected={item.isSelected}
        key={item.value}
        label={item.label}
        modifyFilters={modifyFilters}
        setFilters={setFilters}
        type={type}
        value={item.value}
      />
    ))}
  </div>
);

export default FilterSection;
