import React from 'react';

const PlayIcon = ({ iconColor }) => (
  <div className="play-icon">
    <svg viewBox="0 0 36.777084 36.777084" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-93.327054,-80.384395)">
        <g transform="matrix(0.26458333,0,0,0.26458333,85.513909,72.737936)">
          <g>
            <path
              d="m 74.39,102.4 c 0,-2.2 0,-5.8 0,-8 v -32 c 0,-2.2 1.56,-3.1 3.46,-2 l 27.71,16 c 1.91,1.1 5.02,2.9 6.93,4 l 27.71,16 c 1.91,1.1 1.91,2.9 0,4 l -27.71,16 c -1.91,1.1 -5.02,2.9 -6.93,4 l -27.71,16 c -1.91,1.1 -3.46,0.2 -3.46,-2 z"
              fill={iconColor}
            />
          </g>
          <circle
            cx="99.029999"
            cy="98.400002"
            fill="none"
            stroke={iconColor}
            strokeWidth={9}
            strokeMiterlimit={10}
            r="65"
          />
        </g>
      </g>
    </svg>
  </div>
);

export default PlayIcon;
