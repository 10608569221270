import React from 'react';
import WhiteLabelSmallHeroBanner from './WhiteLabelSmallHeroBanner';

const WhiteLabelSmallHeroBannerWidget = ({ heroSlide }) => (
  <section className="hero hero-small">
    <WhiteLabelSmallHeroBanner heroSlide={heroSlide} />
  </section>
);

export default WhiteLabelSmallHeroBannerWidget;
