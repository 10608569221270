import React from 'react';

const LazyLoadImg = ({ alt, className, id, src, style }) => (
  <img
    alt={alt}
    className={className}
    id={id}
    src={src.url}
    height={src.height}
    width={src.width}
    loading="lazy"
    style={style}
  />
);

export default LazyLoadImg;
