import React from 'react';
import {
  CONTACT_FORM_LINK,
  DOWNLOAD_LINK,
  EMAIL,
  EMAIL_LINK,
  FAX,
  MAIL,
  PHONE,
  WEB_LINK,
  ZIP_CODE
} from '@/constants/data-table';

const DataSection = ({
  address,
  contactFormURL,
  contactFormURLText,
  description,
  downloadLink,
  downloadLinkText,
  email,
  fax,
  phone,
  title,
  webURL,
  webURLText,
  webURLTarget,
  zip
}) => (
  <div className="section-item">
    {downloadLink && (
      <div className="sub-section link">
        <a
          href={downloadLink}
          target="_blank"
          rel="noopener noreferrer"
          title={downloadLinkText || `${DOWNLOAD_LINK}`}
        >
          {downloadLinkText || `${DOWNLOAD_LINK}`}
        </a>
      </div>
    )}
    {title && <span className="section-header">{title}</span>}
    {description && <p>{description}</p>}
    {email && (
      <div className="sub-section">
        <span className="label">{EMAIL}</span>
        <p>
          <a href={`mailto:${email}`} title={`Email ${title}`}>
            {EMAIL_LINK}
          </a>
        </p>
      </div>
    )}
    {phone && (
      <div className="sub-section">
        <span className="label">{PHONE}</span>
        <p>{phone}</p>
      </div>
    )}
    {fax && (
      <div className="sub-section">
        <span className="label">{FAX}</span>
        <p>{fax}</p>
      </div>
    )}
    {address && (
      <div className="sub-section">
        <span className="label">{MAIL}</span>
        <span dangerouslySetInnerHTML={{ __html: address }} />
      </div>
    )}
    {zip && (
      <div className="sub-section">
        <span className="label">{ZIP_CODE}</span>
        <p>{zip}</p>
      </div>
    )}
    {contactFormURL && (
      <div className="sub-section">
        <a href={contactFormURL} title={contactFormURLText || `${CONTACT_FORM_LINK}`}>
          {contactFormURLText || `${CONTACT_FORM_LINK}`}
        </a>
      </div>
    )}
    {webURL && (
      <div className="sub-section">
        <a href={webURL} title={webURLText || `${WEB_LINK}`} target={webURLTarget}>
          {webURLText || `${WEB_LINK}`}
        </a>
      </div>
    )}
  </div>
);

export default DataSection;
