import React, { useState } from 'react';

import arrowUp from 'images/icons/layout/Arrow-Up-Purple.svg';
import arrowDown from 'images/icons/layout/Arrow-Down-Blue.svg';

import AnimateHeight from 'react-animate-height';
import BusinessUnitItem from '@/components/layout/shared/BusinessUnitItem';

import { ALL_ITEMS, ALL_HEALTHBENEFITS } from '@/constants/newsroom';
import FilterSection from './FilterSection';

const SearchFilters = ({
  allItems,
  clearFilters,
  filterCount,
  getData,
  isBusinessUnit,
  isLoaded,
  modifyFilters,
  page,
  response,
  setFilters
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAccordionExpanded, setAccordionIsExpanded] = useState(false);

  const list = allItems;

  let businessUnits = [];
  let filterItems = [];
  const toggleFilters = () => {
    setIsExpanded(!isExpanded);
    setAccordionIsExpanded(false);
  };

  if (isLoaded && list.length > 0) {
    const filtersListing = response.filters;

    const businessUnitFilters = filtersListing.find(
      (filterType) => filterType.key === 'businessunittitle'
    );

    if (businessUnitFilters) {
      businessUnits = businessUnitFilters.items.map((item) => ({
        isSelected: item.isSelected,
        label: item.label,
        value: item.value
      }));
    }

    filterItems = filtersListing
      .filter((filterType) => filterType.key !== 'businessunittitle')
      .map((filter) => ({
        title: filter.label,
        type: filter.key,
        values:
          filter.items?.map((item) => ({
            isSelected: item.isSelected,
            label: item.label,
            value: item.value
          })) ?? []
      }));
  }

  const toggleBUFilters = () => {
    setAccordionIsExpanded(!isAccordionExpanded);
    setIsExpanded(false);
  };

  return (
    <>
      <div className="filters">
        <div className="container-fluid">
          <div className="filters-container full-width">
            {isBusinessUnit && (
              <div className="business-unit-list br-lg">
                {businessUnits && businessUnits.length > 0 && (
                  <ul>
                    <BusinessUnitItem
                      getNewsroomData={getData}
                      key=""
                      modifyFilters={modifyFilters}
                      page={page}
                      setFilters={setFilters}
                      title="All"
                      value=" "
                    />
                    {businessUnits.map((item) => (
                      <BusinessUnitItem
                        getNewsroomData={getData}
                        isActive={item.isSelected}
                        key={item.value}
                        modifyFilters={modifyFilters}
                        page={page}
                        setFilters={setFilters}
                        title={item.label}
                        value={item.value}
                      />
                    ))}
                  </ul>
                )}
              </div>
            )}
            {isLoaded && (
              <>
                <div className="accordion-list br-sm">
                  <ul className="accordion">
                    <li className={`${isAccordionExpanded ? ' active' : ''}`}>
                      <button className="btn-no-style" type="button" onClick={toggleBUFilters}>
                        <span className="btn-contain">{ALL_HEALTHBENEFITS}</span>
                        <img
                          src={isAccordionExpanded ? arrowUp : arrowDown}
                          className="chevron"
                          alt="Chevron Arrow"
                        />
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="accordion-list">
                  <ul className="accordion">
                    <li className={`${isExpanded ? ' active' : ''}`}>
                      <button className="btn-no-style" type="button" onClick={toggleFilters}>
                        <span className="btn-contain">
                          {filterCount > 0 && isBusinessUnit
                            ? filterCount > 1
                              ? `${filterCount} Filters`
                              : '1 Filter'
                            : filterCount > 1
                              ? filterCount > 2
                                ? `${filterCount - 1} Filters`
                                : '1 Filter'
                              : ALL_ITEMS}
                        </span>
                        <img
                          src={isExpanded ? arrowUp : arrowDown}
                          className="chevron"
                          alt="Chevron Arrow"
                        />
                      </button>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <AnimateHeight
        duration={500}
        height={isExpanded ? 'auto' : 0}
        animateOpacity
        className="expanded-filters-list"
      >
        <div className="container-fluid">
          <div className="filters-list">
            {filterItems.map((filter) => (
              <FilterSection
                getNewsroomData={getData}
                key={filter.type}
                modifyFilters={modifyFilters}
                setFilters={setFilters}
                title={filter.title}
                type={filter.type}
                values={filter.values}
              />
            ))}
            <div className="reset">
              <button
                className="btn-no-style as-link"
                onClick={() => {
                  getData(1, clearFilters(), false);
                }}
                type="button"
              >
                Reset Filters
              </button>
            </div>
          </div>
        </div>
      </AnimateHeight>
      <AnimateHeight
        duration={500}
        height={isAccordionExpanded ? 'auto' : 0}
        animateOpacity
        className="expanded-filters-list"
      >
        <div className="filters-list">
          <div className="container-fluid">
            {businessUnits && businessUnits.length > 0 && (
              <ul>
                <BusinessUnitItem
                  getNewsroomData={getData}
                  key=""
                  modifyFilters={modifyFilters}
                  page={page}
                  setFilters={setFilters}
                  title="All"
                  value=" "
                />

                {businessUnits.map((item) => (
                  <BusinessUnitItem
                    getNewsroomData={getData}
                    isActive={item.isSelected}
                    key={item.value}
                    modifyFilters={modifyFilters}
                    page={page}
                    setFilters={setFilters}
                    title={item.label}
                    value={item.value}
                  />
                ))}
              </ul>
            )}
          </div>
        </div>
      </AnimateHeight>
    </>
  );
};

export default SearchFilters;
