import React, { useEffect } from 'react';

import getQueryStringParameters from '@/services/queryString.service';
import { scrollToParameter, getAllFAQButtons } from '@/services/scroller.service';

const Scroller = ({ hasAlerts }) => {
  useEffect(() => {
    window.addEventListener('load', () => {
      getQueryStringParameters().then((queryStringParameters) => {
        Object.keys(queryStringParameters).forEach((key) => {
          scrollToParameter(queryStringParameters[key], hasAlerts, key);
        });
      });

      getAllFAQButtons(hasAlerts);
    });
  });

  return <span />;
};

export default Scroller;
