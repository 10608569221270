import React from 'react';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';

const Tile = ({ altText, image, title, statement, guid, selectItem, isActive }) => (
  <div className={`tile${isActive ? ' active' : ''}`}>
    <div className="tile-container">
      <button type="button" className="btn-no-style" onClick={() => selectItem(guid)}>
        <LazyLoadImg src={image} alt={altText || `${title} Tile`} />
        <div className={`content${isActive ? ' active' : ''}`}>
          <h3>{title}</h3>
          <p dangerouslySetInnerHTML={{ __html: statement }} />
        </div>
      </button>
    </div>
    <span className={`circle large circle-no-border${isActive ? ' active' : ''}`} />
  </div>
);

export default Tile;
