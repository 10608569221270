import React from 'react';
import { Element } from 'react-scroll';

import classNames from 'classnames';
import GlobalComponentContainer from '@/components/widgets/GlobalComponentContainer';
import ContentDivider from '@/components/widgets/shared/ContentDivider';
import DesktopAndTabletContainer from '@/components/shared/DesktopAndTabletContainer';
import MobileContainer from '@/components/shared/MobileContainer';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';
import { getSectionClassNames } from '@/utilities/component-color';

const FeaturedContentImageComponent = ({
  altText,
  featuredImage,
  featuredImageMobile,
  featuredText,
  hasContentDivider,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title,
  componentBackgroundColor
}) => (
  <section className={getSectionClassNames(componentBackgroundColor)}>
    <div className="container-fluid">
      <div className="featured-image section-gutter">
        <ConditionalWrapper
          condition={prettyUrlTitle}
          wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
        >
          <GlobalComponentContainer
            {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
          />
          <MobileContainer>
            <LazyLoadImg
              className={classNames({ margin: !!shortDescription })}
              src={featuredImageMobile}
              alt={altText || title}
            />
          </MobileContainer>
          <DesktopAndTabletContainer>
            <LazyLoadImg
              className={classNames({ margin: !!shortDescription })}
              src={featuredImage}
              alt={altText || title}
            />
          </DesktopAndTabletContainer>
          <div className="content">
            <div dangerouslySetInnerHTML={{ __html: featuredText }} />
          </div>
        </ConditionalWrapper>
      </div>
      {hasContentDivider && <ContentDivider />}
    </div>
  </section>
);

export default FeaturedContentImageComponent;
