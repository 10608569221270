import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';

import { useCssVariables } from '@/utilities';

const CtaButton = ({
  children,
  target,
  path,
  buttonColor,
  overrideButtonColor,
  textColor,
  backgroundColor,
  className
}) => {
  const ctaButtonRef = useRef(null);

  const colorScheme = buttonColor.toLowerCase();

  const [cssBackgroundColor, setCssBackgroundColor] = useState(backgroundColor);
  const [cssTextColor, setCssTextColor] = useState(textColor);

  useEffect(() => {
    if (overrideButtonColor) return;

    setCssBackgroundColor(`var(--hero-main-${colorScheme})`);
    if (colorScheme === 'pink' || colorScheme === 'blue') {
      setCssTextColor(`var(--hero-text-white)`);
    } else {
      setCssTextColor(`var(--hero-text-black)`);
    }
  }, [colorScheme, overrideButtonColor]);

  useCssVariables(ctaButtonRef, {
    '--cta-button-text-color': cssTextColor,
    '--cta-button-background-color': cssBackgroundColor
  });

  return (
    <div className={classNames('btn-container', className)} ref={ctaButtonRef}>
      <a className="btn" href={path} target={target}>
        {children}
      </a>
    </div>
  );
};

export default CtaButton;
