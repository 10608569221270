import React from 'react';
import CtaButton from '@/components/shared/ctaButton/CtaButton';
import { FadeInContainer } from '@/components/shared/FadeInContainer';
import FeaturedCta from '@/components/widgets/featuredCtaGroup/FeaturedCta';

const HomepageHeroSlide = ({ slide }) => {
  const { title, description, media, ctaButton, featuredCtas } = slide;

  return (
    <div className="homepage-hero-container">
      <div className="container-fluid">
        <FadeInContainer direction="left" className="width-full">
          <div className="homepage-hero-content">
            <h1>{title}</h1>
            <p>{description}</p>
            {ctaButton.hasCtaButton && (
              <div className="button-wrapper">
                <CtaButton {...ctaButton}>{ctaButton.text}</CtaButton>
                {ctaButton.hasSecondCtaButton && (
                  <CtaButton
                    backgroundColor={ctaButton.secondBackgroundColor}
                    textColor={ctaButton.secondTextColor}
                    path={ctaButton.secondPath}
                    target={ctaButton.secondTarget}
                    buttonColor={ctaButton.secondButtonColor}
                    overrideButtonColor={ctaButton.secondOverrideButtonColor}
                  >
                    {ctaButton.secondText}
                  </CtaButton>
                )}
              </div>
            )}
          </div>
        </FadeInContainer>
        <FadeInContainer direction="right">
          <div className="homepage-hero-image">
            <img src={media} alt={title} />
          </div>
        </FadeInContainer>
      </div>
      {featuredCtas && (
        <div className="container-fluid featured-ctas">
          {featuredCtas.map((cta) => (
            <FeaturedCta cta={cta} key={cta.ctaName} />
          ))}
        </div>
      )}
    </div>
  );
};

export default HomepageHeroSlide;
