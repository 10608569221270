import React, { useRef } from 'react';
import { useCssVariables } from '@/utilities';

const FileStep = ({ backgroundColor, icon, number, text, textColor }) => {
  const numberRef = useRef(null);

  useCssVariables(numberRef, {
    '--number-background-color': `${backgroundColor || 'unset'}`,
    '--number-text-color': `${textColor || 'unset'}`
  });

  return (
    <div className="highlight">
      {icon ? (
        <img className="step-image" src={icon} alt="Step Icon" />
      ) : (
        <span className="number">{number}</span>
      )}
      <span className="text" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default FileStep;
