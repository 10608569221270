import React from 'react';

const SliderArrow = ({
  className,
  to,
  img,
  onClick = null,
  currentSlide = 0,
  checkIfHidden = () => false
}) => {
  if (checkIfHidden(currentSlide)) return false;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`button button--text button--icon ${className}`}
      aria-label={to}
    >
      <img className="icon" src={img} alt="arrow" />
    </button>
  );
};

export default SliderArrow;
