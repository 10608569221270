import React, { useCallback, useEffect, useRef, useState } from 'react';
import useMediaQuery from '@/hooks/useMediaQuery';
import caretDown from 'images/icons/layout/caret-down-solid.svg';
import { FillFromMouseButton, FillFromMouseLink } from '../FillAnimation';
import NavigationPanel from './NavigationPanel';
import NavigationPanelItem from './NavigationPanelItem';

const NavigationLink = ({ item, ...passThroughProps }) => (
  <FillFromMouseLink href={item.path} {...passThroughProps}>
    {item.icon && <img className="link-icon" src={item.icon} alt="" width="16" height="16" />}
    <span>{item.name}</span>
  </FillFromMouseLink>
);

const NavigationButton = ({ item, ...passThroughProps }) => (
  <FillFromMouseButton {...passThroughProps}>
    {item.icon && <img className="link-icon" src={item.icon} alt="" width="16" height="16" />}
    <span>{item.name}</span>
    <img className="dropdown-caret" src={caretDown} alt="" width="10" height="16" />
  </FillFromMouseButton>
);

const NavigationMenuItem = ({ item, isParentOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeout = useRef(null);
  const isDesktop = useMediaQuery('(min-width: 1025px)');

  // On desktop, close sub-menu after navigated (mouse leave/blur) away from
  const closeWithDelay = useCallback(
    (delay) => {
      if (isDesktop) {
        timeout.current = setTimeout(() => setIsOpen(false), delay);
      }
    },
    [isDesktop]
  );

  const handleButtonClick = useCallback(() => {
    setIsOpen((value) => !value);

    const customEvent = new CustomEvent('navigation-item-click', { detail: item.nodeId });
    window.dispatchEvent(customEvent);
  }, [item.nodeId]);

  // On desktop, close other sub-menus when another sub-menu is opened
  useEffect(() => {
    const handleNavigationItemClick = (e) => {
      if (isDesktop && e.detail !== item.nodeId) {
        setIsOpen(false);
      }
    };

    window.addEventListener('navigation-item-click', handleNavigationItemClick);

    return () => {
      window.removeEventListener('navigation-item-click', handleNavigationItemClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  // Close sub-menus when entire menu is closed
  useEffect(() => {
    if (!isParentOpen) {
      setIsOpen(false);
    }
  }, [isParentOpen]);

  return (
    <li onMouseLeave={() => closeWithDelay(500)} onMouseEnter={() => clearTimeout(timeout.current)}>
      {item.navigationChildren.length ? (
        <>
          <NavigationButton
            className="navigation-button"
            item={item}
            aria-label={`Open navigation panel for ${item.name}`}
            data-is-open={isOpen}
            tabIndex={isParentOpen || isDesktop ? undefined : -1}
            onClick={() => handleButtonClick()}
          />
          <NavigationPanel
            isOpen={isOpen}
            onBlur={() => closeWithDelay(1500)}
            onFocus={() => clearTimeout(timeout.current)}
          >
            {item.navigationChildren.map((child, index) => (
              <NavigationPanelItem key={child.nodeId} item={child} isOpen={isOpen} index={index} />
            ))}
          </NavigationPanel>
        </>
      ) : (
        <NavigationLink className="navigation-link" item={item} />
      )}
    </li>
  );
};

export default NavigationMenuItem;
