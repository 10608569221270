import React from 'react';
import FAArrowRight from '@/images/icons/layout/FA-Arrow-Right.svg';

const HorizontalTile = ({ tile }) => {
  const { header, subheader, image, link, target } = tile;

  return (
    <a className="horizontal-tile" href={link} data-hoverable target={target}>
      <img className="horizontal-tile-image" src={image} alt="alt" />
      <div className="horizontal-tile-gradient" />
      <div className="horizontal-tile-content">
        <div>
          <span className="horizontal-tile-header">{header}</span>
          <p className="horizontal-tile-subheader">{subheader}</p>
        </div>
        <span className="horizontal-tile-icon">
          <img className="step-image" src={FAArrowRight} alt="Step Icon" />
        </span>
      </div>
    </a>
  );
};

export default HorizontalTile;
