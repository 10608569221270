import React from 'react';
import { Element } from 'react-scroll';
import { getSectionClassNames } from '@/utilities/component-color';

import AnchorWrapper from '@/components/layout/shared/AnchorWrapper';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';

const SocialFooterComponent = ({
  prettyUrlTitle,
  socialLinks,
  title,
  componentBackgroundColor
}) => (
  <section className={getSectionClassNames(componentBackgroundColor)}>
    <div className="container-fluid">
      <div className="social-footer section-gutter">
        <ConditionalWrapper
          condition={prettyUrlTitle}
          wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
        >
          <h2>{title}</h2>
          <ul>
            {socialLinks.map((link, index) => (
              <li className="inline" key={link.name || index}>
                <AnchorWrapper {...link}>
                  <img src={link.icon} alt={`Social icon for ${link.name}`} />
                </AnchorWrapper>
              </li>
            ))}
          </ul>
        </ConditionalWrapper>
      </div>
    </div>
  </section>
);

export default SocialFooterComponent;
