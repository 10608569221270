import { useEffect } from 'react';
import UserAgentParser from 'ua-parser-js';

export const isMobile = (userAgent) =>
  !!(userAgent && UserAgentParser(userAgent).device.type === 'mobile');

export const isTablet = (userAgent) =>
  !!(userAgent && UserAgentParser(userAgent).device.type === 'tablet');

export const isTabletOrMobile = (userAgent) => isMobile(userAgent) || isTablet(userAgent);

export const isDesktop = (userAgent) => !isTabletOrMobile(userAgent);

export const isDesktopOrTablet = (userAgent) => isDesktop(userAgent) || isTablet(userAgent);

export const isRunningOnServer = () => !(typeof window !== 'undefined' && window.document);

/**
 * Dynamically sets CSS variables on the given element.
 * @param {React.MutableRefObject<any>} ref The element ref that you wish to change the variables of.
 * @param {Record<string, string>} values An object with key-value pairs of variable names and value.
 */
export const useCssVariables = (ref, values) => {
  useEffect(() => {
    if (!ref.current) return;

    Object.entries(values).forEach(([variableName, value]) => {
      if (!value) return;

      ref.current.style.setProperty(variableName, value);
    });
  }, [ref, values]);
};
