import React, { useState } from 'react';
import searchWhite from 'images/icons/layout/Search.svg';

const MaterialLibraryLanding = ({ searchLandingPath, topics }) => {
  const keywordPlaceholderText = React.useRef(null);
  const keywordClearButton = React.useRef(null);
  const [query, setQuery] = useState('');

  const handleKeywordSubmit = (e) => {
    e.preventDefault();
    window.location.href = `${searchLandingPath}?query=${query}`;
  };

  const handleTopicSubmit = (e) => {
    window.location.href = `${searchLandingPath}?topics=${e.target.value}`;
  };

  const resetKeywordClasses = () => {
    keywordPlaceholderText.current.classList.remove('moved');
    keywordClearButton.current.classList.remove('visible');
    setQuery('');
  };

  const handleKeywordInput = (e) => {
    if (e.target.value) {
      keywordPlaceholderText.current.classList.add('moved');
      keywordClearButton.current.classList.add('visible');
      setQuery(e.target.value);
    } else {
      resetKeywordClasses();
    }
  };

  return (
    <div className="material-library material-landing section-white">
      <section className="container-fluid search-area">
        <form className="search-form" onSubmit={handleKeywordSubmit} onReset={resetKeywordClasses}>
          <div className="search-input">
            <label>
              <span ref={keywordPlaceholderText} className="placeholder">
                Search by Keyword
              </span>

              <input onInput={handleKeywordInput} type="text" value={query} />
            </label>

            <input ref={keywordClearButton} type="reset" className="clear-search" value="Clear" />
            <button type="submit" className="btn btn-no-style search-icon">
              <img src={searchWhite} alt="Search Icon" />
            </button>
          </div>
        </form>

        <div className="or">
          <span>or</span>
        </div>

        <div className="search-input">
          <label>
            <span className="sr-only">Search by Topic</span>
            <select onChange={handleTopicSubmit} className="custom">
              <option disabled selected>
                Search by Topic
              </option>
              {topics.map((topic) => (
                <option key={topic} value={topic}>
                  {topic}
                </option>
              ))}
            </select>
          </label>
        </div>
      </section>
    </div>
  );
};

export default MaterialLibraryLanding;
