import React, { useEffect, useRef } from 'react';
import useMediaQuery from '@/hooks/useMediaQuery';

const NavigationPanelItem = ({ item, index, isOpen = false }) => {
  const cardRef = useRef(null);
  const isDesktop = useMediaQuery('(min-width: 1025px)');

  useEffect(() => {
    if (isDesktop) {
      if (cardRef.current && !isOpen) {
        cardRef.current.style.bottom = `${(index + 1) * 80}px`;
      } else if (cardRef.current && isOpen) {
        cardRef.current.style.bottom = '0px';
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <a
      ref={cardRef}
      href={item.path}
      className="navigation-panel-item"
      tabIndex={isOpen ? undefined : -1}
      data-hoverable
    >
      <img src={item.icon} alt="" />
      <h3>{item.name}</h3>
      <p>{item.description}</p>
    </a>
  );
};

export default NavigationPanelItem;
